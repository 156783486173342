'use client';
import Link from 'next/link';
import React from 'react';

export default function NotFound() {
	return (
		<html lang="en">
			<head>
				{
					<link
						href="https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;700&display=swap"
						rel="stylesheet"
					/>
				}
			</head>
			<body
				style={{
					fontFamily: "'Mulish', sans-serif",
				}}
			>
				<div
					style={{
						display: 'flex',
						height: '100vh',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							maxWidth: '800px',
							gap: '2.5rem',
						}}
					>
						<h2
							style={{
								margin: 0,
								textAlign: 'center',
								fontSize: '2.25rem',
								fontWeight: 'bold',
								color: '#293455',
							}}
						>
							404 - Lehekülge ei leitud
						</h2>

						<p
							style={{
								textAlign: 'center',
								lineHeight: '2rem',
								color: '#293455',
								maxHeight: '6rem',
								overflow: 'hidden',
								textOverflow: 'clip',
							}}
						>
							Antud veebilehte ei eksisteeri. Leht võib olla kustutatud või lehe
							asukoht võib olla muudetud.
						</p>

						<Link
							href="/"
							style={{
								display: 'inline-block',
								borderRadius: '0.75rem',
								backgroundColor: '#d1ad73',
								padding: '1rem 1.5rem',
								fontSize: '0.875rem',
								fontWeight: '600',
								color: '#ffffff',
								textAlign: 'center',
								textDecoration: 'none',
								cursor: 'pointer',
								width: 'fit-content',
							}}
						>
							Mine avalehele
						</Link>
					</div>
				</div>
			</body>
		</html>
	);
}
